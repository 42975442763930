@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.articles-carousel {
  $block-name: &;

  margin-left: -10px;
  margin-right: -10px;
  position: relative;
  z-index: 0;

  .swiper-container {
    overflow: visible;
  }

  .swiper-wrapper {
    overflow: visible;
  }

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    width: 25%;

    @include mq($to: xl) {
      width: 33.3333%;
    }

    @include mq($to: lg) {
      width: 50%;
    }

    @include mq($to: 700px) {
      width: 325px;
    }
  }

  &__cell {
    padding-left: 10px;
    padding-right: 10px;
  }

  &--per-view-3 {
    .swiper-slide {
      width: 33.3333%;

      @include mq($to: xl) {
        width: 33.3333%;
      }

      @include mq($to: lg) {
        width: 50%;
      }

      @include mq($to: 700px) {
        width: 325px;
      }
    }
  }
}
